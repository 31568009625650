"use strict";
(function (app) {
    'use strict';
    var STRIP_HTML_REGEX = /<[^>]+>/gm;
    app.filter('stripHTML', function () {
        return function (text) {
            return String(text || '').replace(STRIP_HTML_REGEX, '');
        };
    });
    var Sanitize = function ($filter) {
        this.$filter = $filter;
    };
    /**
     * Strips HTML from a string
     *
     * @param {string}
     *
     * @returns {string} that has been stripped of HTMl
     */
    Sanitize.prototype.stripHTML = function (string) {
        return this.$filter('stripHTML')(string);
    };
    Sanitize.$inject = ['$filter'];
    app.service('prSanitize', Sanitize);
})(angular.module('qualtrics.pr'));
