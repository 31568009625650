"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FEATURES = void 0;
exports.FEATURES = {
    EX_SHARE_ENABLENPS: 'EX_SHARE_EnableNPS',
    EX_SHARE_MULTI_SELECT_ORGH: 'EX_SHARE_MultiSelectOrgH',
    EX_DATA_PLATFORM_FIELD_SET_TRANSLATION: 'EX.DataPlatform.FieldSetTranslation',
    EX_WIDGETS_RSDKHEATMAP_TRENDING: 'EX_WIDGETS_RSDKHeatmap_Trending',
    EX_WIDGETS_RSDK_NPS: 'EX_WIDGETS_RSDK_NPS',
    // TODO: Remove once hiding empty rows work is GA'd: https://qualtrics.atlassian.net/browse/EXWIDGETS-5034
    EX_WIDGETS_HIDE_EMPTY_ROWS: 'EX_WIDGETS_HIDE_EMPTY_ROWS',
    EX_ENHANCED_CONFIDENTIALITY_ORGH_UPDATE: 'EX.EnhancedConfidentialityOrgHUpdate',
    FAIL_360_EXPORTS_ON_TIMEOUT: 'PrintedReports.Fail360ExportsOnTimeout',
    WIDGET_LIMIT_REPORTS_DEFAULT: 'PrintedReports.WidgetLimitReportsDefault',
    USE_DATA_LAKE: 'PrintedReports.UseDatalake',
    RP_USE_RSDK_RELEASE_CANDIDATE: 'RP.UseRSDKReleaseCandidate',
    RP_USE_RSDK_HOTFIX: 'RP.UseRSDKHotfix',
    ENABLE_FIXED_HEIGHT_TEXT_WIDGET: 'PrintedReports.EnableFixedHeightTextWidget',
    EDITABLE_WIDGETS_PPTX: 'PrintedReports.EditableWidgetsPptx',
    EDITABLE_PPTX_GRID_TABLE: 'PrintedReports.EditablePPTXGridTable',
    EDITABLE_PPTX_DATA_TABLE: 'PrintedReports.EditablePPTXDataTable',
    EDITABLE_PPTX_STATS_TABLE: 'PrintedReports.EditablePPTXStatsTable',
    EDITABLE_PPTX_TEXT: 'PrintedReports.EditablePPTXText',
    EDITABLE_PPTX_BAR: 'PrintedReports.EditablePPTXBar',
    EDITABLE_PPTX_HEATMAP: 'PrintedReports.EditablePPTXHeatmap',
    EDITABLE_PPTX_SCORECARD: 'PrintedReports.EditablePPTXScorecard',
    EDITABLE_PPTX_QUESTION_LIST: 'PrintedReports.EditablePPTXQuestionList',
    INCREASE_360_WIDGET_COUNT_LIMIT: 'PrintedReports.increase360WidgetCountLimit',
    PROCESS_PIPED_TEXT: 'PrintedReports.ResolvePipedTextInLabels',
    LIMIT_SCHEDULED_EMAILS: 'PrintedReports.LimitScheduledEmails',
    HEADER_FOOTER_PIPED_TEXT: 'PrintedReports.HeaderFooterPipedText',
    SEND_SNAPSHOT_ID: 'PrintedReports.SendSnapshotId',
    CHECK_FOR_CONTENT: 'PrintedReports.CheckForContent',
    EX_WIDGETS_PR_RSDKSCORECARD: 'EX_WIDGETS_PR_RSDKScorecard',
    EX_WIDGETS_PR_RSDKQUESTION_LIST: 'EX_WIDGETS_PR_RSDKQuestionList',
    EX_WIDGETS_PR_RSDKPARTICIPATION_SUMMARY_LIST: 'EX_WIDGETS_PR_RSDKParticipationSummary',
    EX_WIDGETS_PR_RSDKENGAGEMENT_SUMMARY_LIST: 'EX_WIDGETS_PR_RSDKEngagementSummary',
    ENABLE_CLAC_FOR_360: 'EX.DataAccessControl.EnableCLACFor360',
    OPTIMIZE_SOURCES_CALL: 'PrintedReports.OptimizeSourcesCall',
    DISPLAY_LOGIC_HIGHEST_LOWEST: 'PrintedReports.DisplayLogicHighestLowest',
    ALIGN_METRIC_DOMAINS_USING_KEY: 'PrintedReports.AlignMetricDomainsUsingKey',
    DONT_RESOLVE_LABELS: 'PrintedReports.DontResolveLabels',
    OPTIMIZE_360_SOURCES: 'PrintedReports.Optimize360SourcesCall',
    IMAGE_WIDGET_ALT_TEXT: 'PrintedReports.ImageWidgetAltText',
    PROFSERV_WIDGETS_MASTERCARD_HEATMAP: 'Profserv.EnableMastercard360Heatmap'
};
