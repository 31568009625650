"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var object_utils_1 = require("@shared/utils/object-utils");
/* global Quill */
(function (module) {
    'use strict';
    module.factory('prPipedTextService', [
        '$filter',
        'prPipedTextTooltipService',
        'prPipedTextDynamicValueService',
        '$timeout',
        'prEventService',
        '$q',
        '$rootScope',
        '$compile',
        'prPipedTextDateService',
        'pr.sourceManagement',
        'prPipedEmbedEventsMapper',
        'prSurveyPipedTextMap',
        'rsEnvironment',
        function ($filter, Tooltip, DynamicValueService, $timeout, eventService, $q, $rootScope, $compile, PipedTextDate, sourceManagement, PipedEmbedEventsMapper, surveyPipedTextMap, env) {
            var dateMenuScope = $rootScope.$new();
            function PipedText(editor, editorLocator, widgetId) {
                this.editor = editor;
                this.$editor = $(editorLocator);
                this.widgetId = widgetId;
                this.tooltip = new Tooltip();
                this.date = new PipedTextDate();
                this.dynamicValue = new DynamicValueService(widgetId);
                this.parchment = Quill.import('parchment');
                this.delta = function () {
                    var Delta = Quill.import('delta');
                    return new Delta();
                };
                this.eventsMapper = new PipedEmbedEventsMapper();
                return this;
            }
            PipedText.translate = $filter('translate');
            PipedText.TOOLTIP_SUFFIX = '-tooltip';
            var proto = PipedText.prototype;
            // TODO: delete all event handlers here during refactor
            // https://qualtrics.atlassian.net/browse/REP-6224
            proto.destroy = function () {
                object_utils_1.clearObject(this);
            };
            proto._isEditorOnDom = function () {
                var container = _.get(this.editor, 'container');
                return document.body.contains(container);
            };
            proto.getEmbedId = function (embed) {
                return '#' + embed.getAttribute('id');
            };
            proto.getTooltipId = function (embed) {
                return this.getEmbedId(embed) + PipedText.TOOLTIP_SUFFIX;
            };
            proto.createTooltipId = function (embed) {
                return embed.getAttribute('id') + PipedText.TOOLTIP_SUFFIX;
            };
            // Update piped text embed class.
            proto.updatePipedTextClass = function (shouldAdd) {
                this.$editor.toggleClass('disable-highlight', shouldAdd);
            };
            proto.hasPipedTextContent = function (ops) {
                return _.some(ops, function (op) {
                    return _.get(op, 'insert.piped-text.ptAttrs', false);
                });
            };
            proto.hasSurveyPipedTextContent = function (widgetTitle) {
                var content = _.get(widgetTitle, 'ops.0.insert');
                if (!content) {
                    return false;
                }
                var tags = _.keys(surveyPipedTextMap.get());
                return _.some(tags, function (tag) {
                    return _.includes(content, tag);
                });
            };
            proto.getTooltipHtml = function (content, includeFormatMenu) {
                var formatMenu = !includeFormatMenu ? '' :
                    '<span class="icon icon-angle-down-sm"' +
                        'style="position: relative;top: 5px;margin-left: 5px;margin-right: -10px;margin-top: -10px"' +
                        'qstrap.select-menu options="options" ng-model="model" items="items()" on-change="updateDateFormat($value)">' +
                        '</span>';
                return '' +
                    '<div class="piped-text tooltip top">' +
                    '<div class="tooltip-arrow"></div>' +
                    '<div class="tooltip-inner">' +
                    '<span class="tooltip-content">' + content + '</span>' +
                    formatMenu +
                    '</div>' +
                    '</div>';
            };
            proto.getTooltipElement = function (tooltipContent, includeFormatMenu, dateMenuScope) {
                var compilePromise = $q.defer();
                if (!includeFormatMenu) {
                    return $q.when($(this.getTooltipHtml(tooltipContent, includeFormatMenu)));
                }
                else {
                    $compile($(this.getTooltipHtml(tooltipContent, includeFormatMenu)))(dateMenuScope, function (tooltip) {
                        return compilePromise.resolve(tooltip);
                    });
                }
                return compilePromise.promise;
            };
            proto.getEmbedOffset = function (embed) {
                var blot = this.parchment.find(embed, true);
                return blot ?
                    blot.offset(this.editor.scroll) :
                    -1;
            };
            proto.getBlot = function (ptId) {
                return _.find($('#' + ptId, this.$editor), '__blot');
            };
            // This assumes that the editor is attached to the DOM
            proto._getPipedTextIndex = function (pipedTextId) {
                var blot = this.getBlot(pipedTextId);
                return this.getEmbedOffset(blot);
            };
            proto._setCursorAfterPipedText = function (pipedTextId) {
                var pipedTextIndex = this._getPipedTextIndex(pipedTextId);
                return this.editor.setSelection(pipedTextIndex + 1);
            };
            proto._attachEmbedHandler = function (embed, event, callback) {
                var _this = this;
                embed.addEventListener(event, function () {
                    var args = [];
                    for (var _i = 0; _i < arguments.length; _i++) {
                        args[_i] = arguments[_i];
                    }
                    if (_this.editor) {
                        return callback.apply(void 0, args);
                    }
                    // the piped text instance was deleted
                    // TODO: instead of this pattern,
                    // delete all events triggers when piped text is destroyed
                    // https://qualtrics.atlassian.net/browse/REP-6224
                    embed.removeEventListener(event, callback);
                });
            };
            proto.attachEmbedHandlers = function () {
                var self = this;
                // Attach eventListener for select event on the newly added embeds, so that Quill's
                // selected range can be updated appropriately.
                // This hack is required due to user-select css applied on the embed
                var allPipedTextEmbeds = $('.piped-text-highlight[attach-event-listener]', this.$editor);
                allPipedTextEmbeds
                    .each(function () {
                    var embed = this;
                    var mouseIsOver = false;
                    embed.removeAttribute('attach-event-listener');
                    self._attachEmbedHandler(embed, 'click', function (ev) {
                        ev.stopImmediatePropagation();
                        var index = self.getEmbedOffset(ev.target);
                        if (index !== -1) {
                            self.editor.setSelection(index, 1, 'user');
                        }
                    });
                    // Below listeners are for the tooltip
                    self._attachEmbedHandler(embed, 'mouseenter', function () {
                        // Remove all listeners when out of focus
                        // Create a tooltip div and position it.
                        if (!mouseIsOver) {
                            mouseIsOver = true;
                            $timeout(function () {
                                enableTooltip();
                            }, 1000);
                        }
                        function enableTooltip() {
                            var ptAttrs = JSON.parse(embed.getAttribute('ptAttrs'));
                            var tooltipContent = ptAttrs.tooltipContent || '';
                            function update(embed, $value) {
                                $(self.getTooltipId(embed)).remove();
                                mouseIsOver = false;
                                embed.innerText = self.date.updateDateFormat($value.ptAttrs);
                                var _ptAttrs = JSON.parse(embed.getAttribute('ptAttrs'));
                                _ptAttrs.tooltipContent = self.tooltip.getToopltipContent($value.ptAttrs) || embed.innerText;
                                _ptAttrs.innerText = embed.innerText;
                                embed.setAttribute('ptAttrs', JSON.stringify(_ptAttrs));
                                var index = self.getEmbedOffset(embed);
                                if (index !== -1) {
                                    eventService.trigger('pipedText-' + self.widgetId + '-set-selection', index + 2);
                                    eventService.stream(self.widgetId).trigger('piped-text-save-state');
                                }
                            }
                            dateMenuScope.items = function () {
                                $timeout(function () {
                                    eventService.trigger('pipedText-' + self.widgetId + '-focus');
                                });
                                var ptAttrs = JSON.parse(embed.getAttribute('ptAttrs'));
                                return self.date.getDateMenu(ptAttrs.attrName, ptAttrs.sourceId);
                            };
                            // Set a class name to the menu and
                            // dont remove the tooltip when user is hovering over the menu
                            dateMenuScope.options = {
                                className: 'piped-text-date-format-menu'
                            };
                            dateMenuScope.updateDateFormat = _.partial(update, embed);
                            var getTooltipLeftPosition = function (editorPos, embedPos, tooltipPos) {
                                return editorPos.left + embedPos.left + embedPos.width / 2 - tooltipPos.width / 2 + 'px';
                            };
                            return self.getTooltipElement(tooltipContent, ptAttrs.type === 'date', dateMenuScope)
                                .then(function (tooltip) {
                                // Remove any existing tooltips and date format menu from other embeds
                                $('.piped-text.tooltip').remove();
                                $('.' + dateMenuScope.options.className).remove();
                                tooltip.attr('id', self.createTooltipId(embed));
                                $(document.body).append(tooltip);
                                var index = self.getEmbedOffset(embed);
                                if (index !== -1) {
                                    var embedPos = self.editor.getBounds(index, 1);
                                    var editorPos = self.$editor[0].getBoundingClientRect();
                                    var tooltipPos = tooltip[0].getBoundingClientRect();
                                    var top_1 = editorPos.top + embedPos.top - tooltipPos.height + 'px';
                                    var left = getTooltipLeftPosition(editorPos, embedPos, tooltipPos);
                                    // TODO: Move to CSS
                                    tooltip.attr('style', 'display:none;pointer-events: auto;user-select:none;left:' + left + ';top:' + top_1);
                                    $(self.getTooltipId(embed)).mouseover(function () {
                                        if (mouseIsOver) {
                                            $timeout.cancel($(this).data('timeoutId'));
                                        }
                                    }).mouseleave(function () {
                                        if (mouseIsOver && !$('.' + dateMenuScope.options.className).length) {
                                            $(self.getTooltipId(embed)).remove();
                                            mouseIsOver = false;
                                        }
                                    });
                                    if (mouseIsOver) {
                                        tooltip.fadeIn(600);
                                    }
                                    else {
                                        tooltip.remove();
                                    }
                                }
                            });
                        }
                    });
                    self._attachEmbedHandler(embed, 'mouseout', function () {
                        if (mouseIsOver) {
                            var tooltipCancelTimerId = $timeout(function () {
                                var tooltip = $(self.getTooltipId(embed));
                                tooltip.remove();
                                mouseIsOver = false;
                            }, 1000);
                            $(self.getTooltipId(embed)).data('timeoutId', tooltipCancelTimerId);
                        }
                    });
                    var ptAttrs = JSON.parse(embed.getAttribute('ptAttrs'));
                    // Update all events. So that exisintg embeds can listen to the newly
                    // added events
                    ptAttrs.events = self.eventsMapper.getEvents(ptAttrs.type, ptAttrs.attrName);
                    _.forEach(ptAttrs.events, function (evt) {
                        eventService.on(evt, function callback() {
                            if (!self.editor) {
                                // the piped text instance was deleted
                                // TODO: instead of this pattern,
                                // delete all events triggers when piped text is destroyed
                                // https://qualtrics.atlassian.net/browse/REP-6224
                                return eventService.off(evt, callback);
                            }
                            var _ptAttrs = JSON.parse(embed.getAttribute('ptAttrs'));
                            delete _ptAttrs.tooltipContent;
                            delete _ptAttrs.innerText;
                            var index = self.getEmbedOffset(embed);
                            if (index !== -1) {
                                self._replaceEmbed(_ptAttrs, index);
                            }
                            else {
                                eventService.off(evt, callback);
                            }
                        });
                    });
                });
            };
            proto._replaceEmbed = function replaceEmbed(ptAttrs, index) {
                // record the format here before removing the text
                var format = this.editor.getFormat(index, 1);
                // delete embed
                var delta = this.delta().retain(index).delete(1);
                this.editor.updateContents(delta);
                // re-insert a new embed
                var item = { ptAttrs: ptAttrs };
                var range = { index: index, length: 0 };
                return this.insertMenuItem(item, range, format);
            };
            /**
             * Searches for and returns the first piped text content in the string
             * @param  {string} content
             * @return {object}
             *  @property {string} - piped text content
             *  @property {Number} - index of the piped text content
             */
            proto.findPipedText = function findPipedText(content) {
                var tags = _.keys(surveyPipedTextMap.get());
                var response;
                for (var i = 0; i < tags.length; ++i) {
                    var tag = tags[i];
                    var index = content.indexOf(tag);
                    if (index > -1 && (!response || index < response.index)) {
                        response = {
                            index: index,
                            string: tag
                        };
                    }
                }
                return response;
            };
            /**
             * Convert piped text from survey question titles
             * to piped text that is readable to pr pipedText engine.
             * @return {Promise} resolves after all text has been translated
             */
            proto.convertSurveyPipedText = function (delta) {
                var self = this;
                var content = _.get(delta, 'ops.0.insert', '');
                var pipedTextEntry = self.findPipedText(content);
                if (!pipedTextEntry) {
                    return $q.resolve();
                }
                var index = pipedTextEntry.index;
                var val = pipedTextEntry.string;
                var ptAttrs = surveyPipedTextMap.get(val);
                // Remove the survey piped text
                delta.ops[0].insert = content.substr(0, index) + content.substr(index + val.length);
                self.editor.setContents(_.cloneDeep(delta));
                return self.insertMenuItem({
                    ptAttrs: ptAttrs
                }, {
                    index: index,
                    length: 0
                }).catch(console.error).then(function () {
                    return self.convertSurveyPipedText(delta);
                });
            };
            /*
             * Data-platform doesn't replace the piped text formats in labels
             * This is the function data-platform uses to create the bracket format
             * we must do this conversion ourselves
             */
            var replaceLocatorsRegex = /[$][{][a-z]{1,4}:\/\/([^}]*)[}]/g;
            var replaceLocators = function (input) {
                return input.replace(replaceLocatorsRegex, function (match, capture) {
                    return '[' + capture.split('/').join('-') + ']';
                });
            };
            proto.resolvePipedText = function (dataString) {
                var _this = this;
                dataString = replaceLocators(dataString);
                var matches = _.uniq(dataString.match(/\[(Field-(Subject|Evaluators)([a-zA-z0-9(%]*)|(First|Second)Person(Possessive|Reflexive))\]/g));
                if (matches.length) {
                    var promises_1 = [];
                    var matchesToResolve_1 = matches.filter(function (match) {
                        var ptAttrs = surveyPipedTextMap.get(match);
                        if (ptAttrs) {
                            promises_1.push(_this.dynamicValue.fetchPipedTextValue([{
                                    insert: {
                                        'piped-text': {
                                            ptAttrs: ptAttrs
                                        }
                                    }
                                }]));
                            return true;
                        }
                        return false;
                    });
                    return $q.all(promises_1).then(function (resolvedPromises) {
                        for (var i = 0; i < resolvedPromises.length; i += 1) {
                            dataString = dataString.replaceAll(matchesToResolve_1[i], resolvedPromises[i][0].insert['piped-text'].ptAttrs.innerText);
                        }
                        return dataString;
                    });
                }
                return $q.when(dataString);
            };
            proto._isWidgetSelected = function () {
                var selectedWidgetId = env.get('selectedWidgetId');
                return this.widgetId === selectedWidgetId;
            };
            proto.insertMenuItem = function (item, range, existingFormat) {
                // Insert a place holder
                var self = this;
                range = range || self.editor.getSelection(true);
                var orgDelta = self.editor.getContents();
                // Deletes all previous consecutive spaces with any formatting
                // The spaces will be re-inserted before inserting the embed
                var totalSpaces = 0;
                if (range.index) {
                    for (; range.index >= 1; range.index--) {
                        if (self.editor.getText(range.index - 1, 1) === ' ' &&
                            !_.isEmpty(self.editor.getFormat((range.index || 1) - 1, 1))) {
                            self.editor.deleteText(range.index - 1, 1, Quill.sources.SILENT);
                            totalSpaces += 1;
                        }
                        else {
                            break;
                        }
                    }
                }
                if (!existingFormat) {
                    existingFormat = self.editor.getFormat((range.index || 1) - 1, 1) || {};
                }
                var _ptAttrs = item.ptAttrs;
                _ptAttrs.innerText = PipedText.translate('PIPED_TEXT.LOADING_ELLIPSES');
                _ptAttrs.tooltipContent = PipedText.translate('PIPED_TEXT.LOADING_CONTEXT', {
                    context: ' ' + _.toLower(self.tooltip.getToopltipContent(_ptAttrs) || _ptAttrs.innerText)
                });
                _ptAttrs.ptId = self.dynamicValue.getRandomId();
                self.editor.updateContents(self.delta()
                    .retain(range.index)
                    .insert(_.repeat(' ', totalSpaces), existingFormat)
                    .insert({
                    'piped-text': {
                        ptAttrs: _ptAttrs
                    }
                }, existingFormat)
                    .insert(range ? '' : ' ', existingFormat));
                if (_ptAttrs.sourceId) {
                    // Update connectedSources for this widget with the new piped text surveyId.
                    sourceManagement.linkSource([_ptAttrs.sourceId], self.widgetId);
                }
                var delta = self.delta().insert({
                    'piped-text': {
                        ptAttrs: _ptAttrs
                    }
                }, existingFormat);
                return self.updateEmbedsWithAcutalValue(delta, orgDelta, _ptAttrs.ptId).then(function () {
                    if (self._isWidgetSelected()) {
                        // setting the cursor causes the screen to scroll
                        // so only scroll to piped text if its for an active widget
                        return self._setCursorAfterPipedText(_ptAttrs.ptId);
                    }
                });
            };
            proto.insertMessage = function (delta) {
                var self = this;
                var hasEmbeds = _.some(delta.ops, function (op) {
                    return _.get(op, 'insert.piped-text.ptAttrs', false);
                });
                if (!hasEmbeds || !self._isEditorOnDom()) {
                    /**
                     * For piped text in widget titles, this function can trigger
                     * before the quill editor has been attached to the DOM.
                     *
                     * This can happen because of the following two consequences of widget chunking:
                     * 		(1) Widget chunking always causes piped text to re-initialize,
                     * 			because quill is re-initialized whenever a widget is chunked.
                     * 		(2) Widget chunking sometimes results in a widget being pushed down
                     * 			several pages, which prevents it from loading (due to lazy loading).
                     *
                     * When both of these events occur, the quill editor will not be attached to the DOM,
                     * because the widget has not fully loaded yet. We should not initialize piped text
                     * in this case, because much of the code assumes that the quill editor is present.
                     *
                     * Piped text will be initialized correctly when the widget is loaded,
                     * because that will initialize the quill editor for the widget title.
                     */
                    self.editor.setContents(delta);
                    return $q.when();
                }
                else {
                    // Insert a place holder indicating that the dynamic values are being fetched
                    var _ptAttrs = {};
                    _ptAttrs.innerText = PipedText.translate('PIPED_TEXT.LOADING');
                    _ptAttrs.tooltipContent = PipedText.translate('PIPED_TEXT.LOADING_WIDGET_DATA');
                    _ptAttrs.ptId = self.dynamicValue.getRandomId();
                    self.editor.setContents(self.delta()
                        .insert({
                        'piped-text': {
                            ptAttrs: _ptAttrs
                        }
                    }, { size: '15pt', font: 'arial', italic: true }));
                    return self.updateEmbedsWithAcutalValue(delta, delta, _ptAttrs.ptId);
                }
            };
            proto.updateEmbedsWithAcutalValue = function (delta, orgDelta, placeHolderId) {
                var self = this;
                var isChange = !_.isEqual(delta, orgDelta);
                var FETCH_ERROR = 'An error occurred fetching piped text data';
                // Fetch dynamic value and then update
                return self.dynamicValue.fetchPipedTextValue(delta.ops || [])
                    .then(function (updatedOps) {
                    var pipedTextErrorOccurred = false;
                    // If there is a tooltip active on the current embed to be updated,
                    // remove it first.
                    $('#' + placeHolderId + PipedText.TOOLTIP_SUFFIX).remove();
                    var index = self._getPipedTextIndex(placeHolderId);
                    if (index !== -1) {
                        var d = self.delta()
                            .retain(index)
                            .delete(1);
                        self.editor.updateContents(d);
                        d = self.delta()
                            .retain(index);
                        // Convert ops array into delta function for one action
                        d = _.reduce(updatedOps, function (_d, op) {
                            if (op.resolved === false) {
                                pipedTextErrorOccurred = true;
                                var _ptAttrs = op.insert['piped-text'].ptAttrs;
                                _ptAttrs.tooltipContent = PipedText.translate('PIPED_TEXT.FAILED_LOADING', {
                                    context: ' ' + self.tooltip.getToopltipContent(_ptAttrs) || _ptAttrs.innerText
                                });
                                _ptAttrs.innerText = PipedText.translate('PIPED_TEXT.FAILED');
                            }
                            return _d.insert(op.insert, op.attributes || {});
                        }, d);
                        self.editor.updateContents(d);
                        if (_.isEqual(delta, orgDelta)) {
                            // Delete the extra new line due to the Delta object
                            self.editor.deleteText(self.editor.getLength() - 1, 1);
                        }
                        $('.piped-text-highlight', self.$editor).filter(function (_index, currentElement) {
                            var _a;
                            try {
                                // Use the tooltipContent to determine if the 
                                // piped text call has errored.
                                var tooltipContent = ((_a = JSON.parse($(currentElement).attr('ptattrs'))) === null || _a === void 0 ? void 0 : _a.tooltipContent) || '';
                                return tooltipContent.startsWith(PipedText.translate('PIPED_TEXT.FAILED_LOADING'));
                            }
                            catch (error) {
                                console.error('Error parsing ptattrs', error);
                                return false;
                            }
                        }).addClass('failed-fetch');
                    }
                    // Trigger save once for all updates
                    eventService.stream(self.widgetId).trigger('piped-text-save-state', {
                        shouldNotSaveState: !isChange
                    });
                    if (pipedTextErrorOccurred) {
                        throw new Error(FETCH_ERROR);
                    }
                })
                    .catch(function (e) {
                    console.error('Failed to get updated piped text content', e);
                    if (self.editor && e.message !== FETCH_ERROR) {
                        // if this was a fetch error, we already placed an error
                        // message for each failed embed. Otherwise, restore the
                        // editor to its initial state
                        self.editor.setContents(orgDelta);
                    }
                    throw new Error(e);
                });
            };
            return PipedText;
        }
    ]);
}(angular.module('qualtrics.pr')));
